import SimpleDate from "@/lib/SimpleDate"
import { max } from "@/lib/array"
import { rruleToShortEnglish } from "@/lib/date"
import { pluralCount, titleize } from "@/lib/string"


const FIRST_DAY_OF_WEEK = 0 // Sunday == 0
const LAST_DAY_OF_WEEK = (FIRST_DAY_OF_WEEK + 6) % 7


export function eventDateInWords(event, opts={}) {
  let date = event.nextDate ? new SimpleDate(event.nextDate) : ''

  if (event.dateFlex === 'none') {
    if (opts?.showRule) {
      date = event.rrule ? rruleToShortEnglish(event.rrule) : ''
    } else if (opts?.showYear) {
      date = date && `${date.monthName.substring(0, 3)} ${date.dayOfMonth}, ${date.year}`
    } else {
      date = date && `${date.monthName.substring(0, 3)} ${date.dayOfMonth}`
    }
  } else if (event.dateFlex === 'month') {
    date = date && date.monthName
  } else if (event.dateFlex === 'evergreen') {
    date = `Evergreen`
  } else {
    date = `${titleize(event.dateFlex)} Weather`
  }

  return date
}


export function indexInWeek(date) {
  return (FIRST_DAY_OF_WEEK + date.dayOfWeek) % 7
}


export async function jazzlinkGetToken () {
  if (!jazzlinkMode()) return
  return await iframeParentCall('GET_TOKEN')
}


export async function jazzlinkGetHref () {
  if (!jazzlinkMode()) return
  return await iframeParentCall('GET_HREF')
}


export async function jazzlinkStorageGetItem (key) {
  if (!jazzlinkMode()) return
  return await iframeParentCall('STORAGE_GET', key)
}


export async function jazzlinkStorageSetItem (key, value) {
  if (!jazzlinkMode()) return
  return await iframeParentCall('STORAGE_SET', [key, value].join('='))
}


// This function tells the parent window to run an action, and waits for the
// parent to send back a results. The `payload` must be a string.
function iframeParentCall (action, payload) {
  // These values are defined in src/jazzlink.js (in socialjazz/website repo)
  const SEPARATOR = '|'
  const SUCCESS_STATUS = 1
  const ACTION_CODES = {
    STORAGE_GET: 1,
    STORAGE_SET: 2,
    GET_TOKEN:   3,
    GET_HREF:    4,
  }

  // Set the default payload to an empty string
  payload ||= ''

  // Make sure the payload is a string, that doesn't contain the separator
  if (typeof payload !== 'string') throw Error('Payload must be a string')
  if (payload.includes(SEPARATOR)) throw Error(`Payload cannot contain "${SEPARATOR}"`)

  // Convert the action name to an action code (defined in ACTION_CODES)
  const actionCode = ACTION_CODES[action]
  if (!actionCode) throw Error(`Invalid action code: ${action}`)

  return new Promise(function(resolve, reject) {
    // Generate a unique ID for this call, so we can verify the response message
    const messageId = uuid()

    // Create functions to start and stop listening to messages
    const startListening = () => window.addEventListener(   'message', messageListener)
    const stopListening  = () => window.removeEventListener('message', messageListener)

    startListening()

    // Throw an error if the parent window doesn't respond, after a brief delay
    const timeoutId = setTimeout(function() {
      stopListening()
      reject(Error('Parent window did not respond in time'))
    }, 1000)

    // Send a message to parent window (format: "messageId|actionCode|payload")
    const message = [messageId, actionCode, payload].join(SEPARATOR)
    window.parent.postMessage(message, '*')

    function messageListener(event) {
      // Make sure the message is from the parent window
      if (event.source !== window.parent) return
      if (typeof event.data !== 'string') return

      // The parent should return a string like this: "messageId|status|result"
      // The status will be "1" for success, or "0" for failure
      const [id, status, result] = event.data.split(SEPARATOR, 3)

      // Verify that this message is a response to the current call
      if (id !== messageId) return

      // The parent has responded, so we can stop the timeout
      clearTimeout(timeoutId)

      // Remove the event listener, so we don't keep listening for messages
      stopListening()

      // NOTE: Intentionally using == instead of === (since status is a string)
      if (status == SUCCESS_STATUS) {
        resolve(result)
      } else {
        reject(Error(result))
      }
    }

  })
}


function uuid() {
  if (window.crypto && window.crypto.randomUUID) {
    return window.crypto.randomUUID()
  } else {
    let uuid = ''
    for (let i = 0; i < 32; i++) {
      uuid += Math.floor(Math.random() * 16).toString(16)
      if (i === 7 || i === 11 || i === 15 || i === 19) {
        uuid += '-'
      }
    }
    return uuid
  }
}


//==============================================================================
// When jazzlink loads, it creates an iframe to launch the app and redirects to
// the /jazzlink page. That page then creates a second iframe to load the
// marketing page (a.k.a. intro_url). So we end up with an iframe inside of
// another iframe.
//
// Some of the links on that marketing page will point back to the app, and when
// the user clicks them, the app will get loaded a second time (inside of the
// marketing iframe). Obviously, we don't want to have a second copy of the app
// running inside of the marketing iframe, if for no other reason than the fact
// that this would break the postMessage communication between the app and the
// jazzlink script that is running on the top level window (because the
// marketing iframe is in between them, intercepting the messages).
//
// The solution is to detect when the app is running inside of the marketing
// iframe, and then send a message to the app running in the parent window,
// asking it to navigate to the requested URL.
//==============================================================================
export function jazzlinkFrameFix() {
  if (!jazzlinkMode()) return
  if (window.parent === window.top) return

  const path = window.location.pathname + window.location.search + window.location.hash
  window.parent.postMessage('jazzlink-navigate|' + path, '*')
}


export function jazzlinkMode() {
  return !!window.parent && window.self !== window.top
}


export function startOfWeek(date) {
  const daysSinceWeekStart = (FIRST_DAY_OF_WEEK - date.dayOfWeek) % 7
  return date.addDays(daysSinceWeekStart)
}


export function endOfWeek(date) {
  const daysUntilWeekEnd = (LAST_DAY_OF_WEEK - date.dayOfWeek) % 7
  return date.addDays(daysUntilWeekEnd)
}


export function datesInWeek (date) {
  const firstDate = startOfWeek(date)

  const days = []

  let d = firstDate
  while (days.length < 7) {
    days.push(d)
    d = d.addDays(1)
  }

  return days
}


export function datesInMonth (date) {
  const firstDate = date.addDays(-1 * (date.dayOfMonth - 1))

  const days = []

  let d = firstDate
  while (d.month === firstDate.month) {
    days.push(d)
    d = d.addDays(1)
  }

  return days
}


export function humanTime (timestamp) {
  let time = new Date(timestamp)
  time.setSeconds(0)
  time.setMilliseconds(0)
  return time.toLocaleTimeString().replace(':00 ', '').toLowerCase()
}


export async function sleep (ms) {
  return new Promise(res => setTimeout(res, ms))
}


export function toDateTimeString(t) {
  const timestamp = new Date(t)
  const hours     = 60 * 60 * 1000
  const now       = new Date()
  const last24    = new Date(now - 24 * hours)

  if (timestamp > last24) return toTimeString(t)

  return [
    toTimeString(t),
    'on',
    toDateString(t),
  ].join(' ')
}


// This should be avoided since it can be off by a full day (due to timezones)
// TODO: Find and replace all uses of this function
export function toDateString(t) {
  return new Date(t).toLocaleDateString()
}


export function toTimeString(t) {
  return new Date(t).toLocaleTimeString().replace(/:\d\d /, ' ').replace(' ', '').toLowerCase()
}

export function toDuration(start, stop) {
  const diff = new Date(stop) - new Date(start)
  const seconds = diff / (1000)
  const minutes = diff / (1000 * 60)
  const hours   = diff / (1000 * 60 * 60)
  const days    = diff / (1000 * 60 * 60 * 24)

  if (days    >= 1) return pluralCount(Math.round(days),      'day')
  if (hours   >= 1) return pluralCount(Math.round(hours),     'hr')
  if (minutes >= 1) return             Math.round(minutes) + ' min'
                    return             Math.round(seconds) + ' sec'
}


// XMLHttpRequest works in older browsers and can do progress
export function uploadFileWithProgress(file, opts={}) {
  const CDN_URL = (
    typeof location === 'undefined' ? ''
    : location.host.endsWith('socialjazz.app') ? 'https://cdn.socialjazz.app'
    : location.host.endsWith('socialjazz.com') ? 'https://cdn.socialjazz.com'
    : 'http://localhost:3453'
  )

  const progressCallback = (typeof opts.progress === 'function') ? opts.progress : function() {}

  // Percent of the time is reserved for the server-side file processing work
  const PROCESSING_RATIO = 0.3

  return new Promise ((resolve, reject) => {
    // Note: FormData sends as "multipart/form-data"
    var data = new FormData()
    data.append("file", file)

    let uploadPath = `${CDN_URL}/upload`

    const queryParams = new Map()
    if (opts.type)   queryParams.set('type',   opts.type)
    if (opts.resize) queryParams.set('resize', true)
    if (queryParams.size > 0) {
      uploadPath += '?' + new URLSearchParams(queryParams).toString()
    }

    var ajax = new XMLHttpRequest()
    ajax.upload.addEventListener("progress", progressHandler)
    ajax.addEventListener("load", completeHandler)
    ajax.addEventListener("error", errorHandler)
    ajax.addEventListener("abort", abortHandler)

    let uploadStarted = new Date()
    let extraProgressTimer = 0

    ajax.open("POST", uploadPath)
    try {
      ajax.send(data)
    } catch (e) {
      errorHandler(e)
    }

    function progressHandler(e) {
      if (extraProgressTimer) return

      const percent = Math.round((e.loaded / e.total) * 100)
      progressCallback(percent * (1 - PROCESSING_RATIO))

      // Keep filling up the progress bar after the file is uploaded (to account
      // for the time spent processing the file on the server-side)
      if (percent >= 100) {
        const uploadDuration = new Date() - uploadStarted
        const totalDuration = uploadDuration / (1 - PROCESSING_RATIO)
        let currentPercent = uploadDuration / totalDuration

        extraProgressTimer = setInterval(() => {
          const totalPercent = (new Date() - uploadStarted) / totalDuration

          // Slow down the progress as it closer to 100%
          const maxGrowth = (1 - currentPercent) / (100 * currentPercent)
          currentPercent = Math.min(totalPercent, currentPercent + maxGrowth)
          const reportedProgress = Math.round(currentPercent * 100)

          // Cap the progress at 99%
          progressCallback(Math.min(99, reportedProgress))
        }, 100)
      }
    }

    function completeHandler(e) {
      progressCallback(100)
      clearInterval(extraProgressTimer)
      try {
        const responseText = e.target.responseText
        const response = JSON.parse(responseText)

        if (e.target && e.target.status >= 400) {
          let errorMessage = `Upload Rejected - ${e.target.status} - ${e.target.statusText}`

          if ((response?.message || '').includes(' content-type ')) {
            errorMessage = `Sorry, but that type of file isn't supported. Try using one of these formats: gif, jpg, png, webp.`
          }
          return reject(Error(errorMessage))
        }
        resolve(response)
      } catch {
        return reject(Error(`Upload Rejected - ${e.target.status} - ${e.target.statusText}`))
      }
    }

    function errorHandler(e) {
      clearInterval(extraProgressTimer)
      console.warn("Upload Failed")
      console.error(e)
      reject(Error('Upload Failed'))
    }

    function abortHandler(e) {
      clearInterval(extraProgressTimer)
      console.warn("Upload Aborted")
      console.error(e)
      reject(Error('Upload Aborted'))
    }
  })
}


export function formattedPhoneNumber(val) {
  let formatted = val.replace(/[^0-9]/g, '')

  if (!formatted.match(/^1?\d{10}$/)) return val

  if (formatted.length > 10 && formatted.startsWith('1')) {
    formatted = formatted.substring(1)
  }
  if (formatted.startsWith('1')) return // Don't format if they started with 1
  formatted = [
    formatted.substring(0,3),
    formatted.substring(3,6),
    formatted.substring(6),
  ].join('-')
  return formatted
}


export function openPopup(url) {
  const windowName     = "socialjazz-popup"
  const width          = Math.min(800, window.innerWidth  - 50)
  const height         = Math.min(800, window.innerHeight - 50)
  const left           = (window.screen.width  / 2) - (width  / 2)
  const top            = (window.screen.height / 2) - (height / 2)
  const windowFeatures = "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top + ",location=no,menubar=no,toolbar=no,resizable=yes,scrollbars=yes,status=no"

  const popup = window.open(url, windowName, windowFeatures)
  popup.focus()
}


export async function getVideoThumbnail (videoFile) {
  return new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video')

      // NOTE: To get Safari on iOS to fire the 'seeked' event we must set...
      video.preload = 'metadata'

      // However, since iOS also has problems with the canvas.toBlob method, we
      // we don't call getVideoThumbnail on iOS at all. Instead, we just upload
      // the video file, and let the server create the thumbnail.

      video.onerror = () => {
        reject(new Error(`Sorry, that type of video file isn't supported.`))
      }

      video.onloadedmetadata = () => {
        video.onerror = null // Avoid calling onerror again after video loads

        const width     = parseInt(video.videoWidth)
        const height    = parseInt(video.videoHeight)
        const duration  = parseFloat(video.duration)


        if (width < 600)    return reject(Error("Sorry. Video must be at least 600 pixels wide."))
        if (height < 600)   return reject(Error("Sorry. Video must be at least 600 pixels tall."))
        if (duration < 3)   return reject(Error("Sorry, but videos need to be at least 3 seconds long."))
        if (duration > 60)  return reject(Error("Sorry, but videos cannot be longer than 60 seconds."))

        // Jump to a few seconds before the end of the video, to take a
        // screenshot, since that spot is likely to show the most important
        // content in the video.
        video.currentTime = max([0, video.duration - 2])

        // Wait until the video has advanced to the time we requested
        video.addEventListener('seeked', function() {

          // Take a screenshot of the video and upload to use as the thumbnail.
          // This is MUCH faster than creating thumbnails (with FFMPEG) on the server.
          const canvas = document.createElement('canvas')

          canvas.width  = width
          canvas.height = height

          const ctx = canvas.getContext('2d')
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

          canvas.toBlob(function(thumbnail) {
            URL.revokeObjectURL(video.src)
            resolve(thumbnail)
          }, 'image/png')
        })
      }

      video.src = URL.createObjectURL(videoFile)
    } catch (e) {
      console.error(e)
      reject(e)
    }
  })
}
